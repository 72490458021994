import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash";



export const attempted_mcqs_slice = createSlice({
    name: "attempted_mcqs",
    initialState: {
        ids : []
    },

    reducers: {
        attempted_mcqs_update: (state, action) => {


            if(_.findIndex(state.ids, (o) => { return _.isMatch(o.id, action.payload.id) }) > -1){}else{ 
                state.ids = [...state.ids, action.payload] 

                localStorage.setItem('attempted_mcqs',  JSON.stringify(state.ids))
            }

        },

        inital_attempted_mcqs_update : (state, action) => {
            state.ids = action.payload     
        }
    }
})

export const {attempted_mcqs_update, inital_attempted_mcqs_update} = attempted_mcqs_slice.actions;

export default attempted_mcqs_slice.reducer