import { Link } from 'gatsby'
import React from 'react'

const HeaderDropDown = () => {
  return (
    <div className='flex flex-col items-center text-gray-100 pb-4'>
        <Link className='border border-gray-900 px-6 py-2 my-1 w-48 text-center hover:bg-gray-600 hover:text-white' to='/'>Home</Link>
        <Link className='border border-gray-900 px-6 py-2 my-1 w-48 text-center hover:bg-gray-600 hover:text-white' to='/history'>History</Link>
        <Link className='border border-gray-900 px-6 py-2 my-1 w-48 text-center hover:bg-gray-600 hover:text-white' to='/404'>404</Link>
    </div>
  )
}

export default HeaderDropDown