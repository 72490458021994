import React, { useEffect } from 'react'
import { StaticImage } from "gatsby-plugin-image"
import Footer from "./footer"
import Header from "./header"
import { useDispatch } from 'react-redux'
import { inital_attempted_mcqs_update } from '../redux/attempted_mcqs_slice'

const Layout = ({ children }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (localStorage.getItem('attempted_mcqs')) {
      dispatch(inital_attempted_mcqs_update(JSON.parse( localStorage.getItem('attempted_mcqs'))))
    }
  }, [])


  return (
    <>
      <div className="blur-sm fixed overflow-hidden opacity-80   h-screen w-screen">
        <StaticImage
          src="../images/header-img1.jpg"
          loading="eager"
          layout="fixed"
          quality={95}
          formats={["auto", "webp", "avif"]}
          placeholder="tracedSVG"
          alt=""
        />
      </div>
      <div className="z-20 absolute w-full h-full">
        <Header />
        <div className="px-6 lg:px-36">{children}</div>
        <Footer />
      </div>
    </>
  )
}

export default Layout
