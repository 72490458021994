/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/css/index.css"

import React from 'react'
import Layout from "./src/components/layout"
import { Provider } from "react-redux"
import store from './src/redux/store'

export const wrapRootElement = ({ element, props }) => {

  return(
    <Provider store={store}>
      <Layout {...props}>
        {element}
      </Layout>
    </Provider>
  )
}