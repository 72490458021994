import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { useState } from "react"
import HeaderDropDown from "./headerDropDown"

const Header = () => {
  const [dropDown, setDropDown] = useState(false)
  return (  
    <header>
      <div className="bg-gray-800  text-4xl flex flex-col justify-around items-center">
        <div className="hidden p-6  text-white max-w-7xl  lg:flex justify-around items-center w-full">
          <Link className='border border-gray-900 px-6 py-2 my-1 w-48 text-center hover:bg-gray-600 hover:text-white' to='/'>Home</Link>
          <Link className='border border-gray-900 px-6 py-2 my-1 w-48 text-center hover:bg-gray-600 hover:text-white' to='/history'>History</Link>
          <Link className='border border-gray-900 px-6 py-2 my-1 w-48 text-center hover:bg-gray-600 hover:text-white' to='/'>Home</Link>
          <Link className='border border-gray-900 px-6 py-2 my-1 w-48 text-center hover:bg-gray-600 hover:text-white' to='/'>Home</Link>
          <Link className='border border-gray-900 px-6 py-2 my-1 w-48 text-center hover:bg-gray-600 hover:text-white' to='/'>Home</Link>
        </div>
        <div className="lg:hidden bg-gray-800 h-20" />
        <div onClick={()=> setDropDown(!dropDown)} className="lg:hidden p-6 absolute right-5 top-1 h-28" >
          <div className="w-8 border m-2 border-solid"></div>
          <div className="w-8 border m-2 border-solid"></div>
          <div className="w-8 border m-2 border-solid"></div>
        </div>
        {dropDown && <HeaderDropDown />}
      </div>

    </header>
  )
}

export default Header
